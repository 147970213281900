<template>
  <v-card width="100%" tile height="100%" class="pa-0 ma-0">
    <v-container fluid class="pa-0" fill-height>
      <v-row align="stretch" class="fill-height">
        <v-col cols="12" md="5" class="text-center pa-0" order="2" order-md="2" v-if="!$vuetify.breakpoint.mobile">
          <v-card color="primary" tile height="100%" class="pa-10 mx-auto" flat dark>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-col>
                <h1>Gestão de Privacidade e Segurança de Dados</h1>
                <h3>
                  Adeque sua empresa à Lei Geral de Proteção de Dados (LGPD)
                </h3>
                <img src="@/assets/lgpd_digisystem_ibm.png" style="max-width:100%;" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="7" order="2" order-md="2">
          <v-row class="fill-height" align="center" justify="center">
            <v-col>
              <div class="px-10">
                <div class="text-center pa-5">
                  <img src="@/assets/logo.png" width="400px" style="max-width:80%;" />
                </div>

                <h1 class="text-color-1">Criar uma conta</h1>
                <p class="caption text-color-2">
                  Crie sua conta e monitore o fluxo dos dados dentro da sua empresa com agilidade e organização, com
                  toda a fundamentação jurídica da LGPD.
                </p>

                <v-form v-model="valid" ref="form" class="mb-5">
                  <b class="caption text-color-2"> Seu Nome </b>
                  <v-text-field placeholder="Nome" prepend-inner-icon="mdi-account" :rules="required"
                    v-model="object.username" color="primary" outlined></v-text-field>

                  <b class="caption text-color-2"> Seu E-mail </b>
                  <v-text-field placeholder="E-mail" prepend-inner-icon="mdi-email" v-model="object.email" color="primary"
                    outlined :rules="[requiredRule, validarEmail]"></v-text-field>

                  <v-row class="my-0 py-0">
                    <v-col cols="12" md="6" class="my-0 py-0">
                      <b class="caption text-color-2"> Nome de sua Empresa </b>
                      <v-text-field placeholder="Nome da empresa" :rules="required" v-model="object.name" color="primary"
                        prepend-inner-icon="mdi-office-building-outline" outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="my-0 py-0">
                      <b class="caption text-color-2"> CNPJ </b>
                      <v-text-field placeholder="XX.XXX.XXX/XXXX-XX" :rules="[required, validarCNPJ]"
                        v-model="object.document" color="primary" v-mask="'##.###.###/####-##'"
                        prepend-inner-icon="mdi-office-building" outlined></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="my-0 py-0">
                    <v-col cols="12" md="6" class="my-0 py-0">
                      <b class="caption text-color-2"> Sua Senha </b>
                      <v-text-field 
                      placeholder="Sua senha" 
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'" 
                      @click:append="showPassword = !showPassword"
                      :rules="[required, passwordRule]"
                      v-model="object.password" 
                      color="primary" 
                      prepend-inner-icon="mdi-key" 
                      outlined
                    ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="my-0 py-0">
                      <b class="caption text-color-2"> Confirme sua senha </b>
                      <v-text-field placeholder="Confirme sua senha" :type="showConfirmPassword ? 'text' : 'password'"
                        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[required, matchPassword]"
                        v-model="confirmPassword" color="primary" prepend-inner-icon="mdi-key-outline" outlined
                        @click:append="showConfirmPassword = !showConfirmPassword"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>

                <div class="mb-10">
                  <v-checkbox hide-details v-model="object.acceptTerms" :rules="[required]">
                    <template v-slot:label>
                      <div>
                        Confirmo a veracidade dos dados empresariais e estou ciente dos
                        <a target="_blank" @click.stop="termsUse" v-on="on">Termos de Uso</a> e
                        <a target="_blank" @click.stop="politicsPrivacy" v-on="on">Política de Privacidade.</a>
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox hide-details v-model="object.acceptNews">
                    <template v-slot:label>
                      <div>
                        Desejo receber mais notícias sobre proteção de dados.
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox hide-details v-model="object.acceptProducts">
                    <template v-slot:label>
                      <div>
                        Gostaria de receber informações sobre todos os produtos da Softwaresul.
                      </div>
                    </template>
                  </v-checkbox>
                </div>

                <div class="text-center">
                  <v-btn depressed color="primary" rounded @click="create()" class="mr-2 mb-2"
                    :block="$vuetify.breakpoint.mobile" :large="$vuetify.breakpoint.mobile">
                    <v-icon small class="mr-2">mdi-check</v-icon>
                    Criar uma conta LGPD Tool
                  </v-btn>
                  <v-btn depressed color="primary" to="/login" class="mr-2 mb-2" rounded outlined
                    :block="$vuetify.breakpoint.mobile" :large="$vuetify.breakpoint.mobile">
                    <v-icon small class="mr-2">mdi-key</v-icon>
                    Já possuo uma conta, desejo entrar
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>      
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import { mask } from 'vue-the-mask'

export default {
  directives: { mask },
  data: () => ({
    object: {},
    form: {},
    confirmPassword: null,
    showPassword: false,
    showConfirmPassword: false,
    required: [(v) => !!v || 'O Campo é necessário'],
    valid: false,
  }),
  computed: {
    matchPassword() {
      return () =>
        (this.object.password && this.object.password === this.confirmPassword) || 'As senhas não são iguais.'
    },
    lengthPassword() {
      return () =>
        (this.object.password && this.object.password.length >= 8) || 'A senha precisa ter no mínimo 8 caracteres..'
    },
    validCnpj() {
      return () =>
        (this.object.document.length == 18 || 'Informe um CNPJ válido.')
    },
  },
  methods: {
    termsUse(e) {
      e.preventDefault()
      const termsUseURL = this.$router.resolve({ path: '/termos-de-uso' }).href;
      window.open(termsUseURL, '_blank');
    },
    politicsPrivacy(e) {
      e.preventDefault()
      const politicaPrivacidadeURL = this.$router.resolve({ name: 'Politica de privacidade' }).href;
      window.open(politicaPrivacidadeURL, '_blank');
    },
    create() {
      if (typeof this.object.document === "undefined") {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
          button: 'Ok!',
        })
        return
      }
      this.$refs.form.validate()
      if (!this.valid) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
          button: 'Ok!',
        })
        return
      }

      else if (!this.object.acceptTerms) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'É necessário aceitar os Termos de Uso para prosseguir.',
          button: 'Ok!',
        })
        return
      }

      this.$store.dispatch('setLoadingInfo', true)
      this.error = false
      const url = `${baseApiUrl}/user/create`
      axios
        .post(url, this.object)
        .then(() => {
          this.$store.dispatch('setErrorInfo', {
            open: true,
            text: 'Perfeito! Agora você pode entrar no LGPD Tools!',
            button: 'Ok!',
            route: '/login',
          })
        })
        .catch(showError)
        .finally(() => {
          this.$store.dispatch('setLoadingInfo', false)
        })
    },
  },
}
</script>

<style></style>
